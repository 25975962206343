import React from 'react';
import HeroSection from './components/HeroSection';
import Header from './components/Header'; // eslint-disable-next-line
import Background from './components/Background';
import Section from './components/Section';
import profilePicture from './assets/images/ash-edmonds-Koxa-GX_5zs-unsplash.jpg';



// Import project images
import project1Image from './assets/images/horizontravels4portfolio.jpg';
import project2Image from './assets/images/watchmanapp.jpg';
import project3Image from './assets/images/Mockup.jpg'; // Ensure this path is correct
import project4Image from './assets/images/cityexplorer-thumb.jpg';
import project5Image from './assets/images/Mockup4portfolioitself.jpg';
import project6Image from './assets/images/arduinomockup1.jpg';


// Import custom styles
import './styles.css';


const App = () => {
  return (
    <>
      <div className="custom-hero-bg">
        <Header />
        <HeroSection />
      </div>
      <main>
      <section id="about" className="about-section py-8 mt-8 bg-white rounded-lg p-8 w-full">
  <div className="text-center">
    <div className="flex flex-col md:flex-row items-center justify-center">
      <img src={profilePicture} alt="Raiyan Abedin" className="w-32 h-32 md:w-80 md:h-80 rounded-lg mb-4 md:mb-0 md:mr-8" />
      <div className="text-lg mt-4">
        <h2 className="text-3xl font-bold mb-4">About Me</h2>
        <p>
          I'm <span className="font-bold text-secondary">Raiyan Abedin</span>! I am a final year <span className="font-bold text-secondary">Software Engineering For Business</span> undergraduate at the <span className="font-bold text-secondary">University of The West of England</span>. I have built extensive knowledge as a Developer through diverse projects both during my studies and independently. I've gained practical experience in <span className="font-bold text-secondary">Full-Stack Development, Data Structures and Algorithms, Cybersecurity, API Integration</span> and much more! I have experience in multiple programming languages including <span className="font-bold text-secondary">Python, C, React, Java, PHP</span>, and others. My academic journey has sparked a passion for leveraging technology to solve real-world challenges and drive innovation.
        </p>
        <p>
          I am eager to build meaningful connections within the tech community. I look forward to collaborating, learning, and contributing to the dynamic world of technology and business.
        </p>
        <p>Thank you for taking the time to view my portfolio. Please feel free to explore some of my projects!</p>
      </div>
    </div>
  </div>
</section>


        <div className="w-full custom-project-bg text-white py-8">
          <section id="projects" className="py-8 mt-8 w-full">
            <div className="text-center">
              <h2 className="text-4xl font-extrabold mb-4"> My Projects</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 justify-center">
                <div className="relative project-card project-card">
                  <img src={project1Image} alt="Travel Website" className="project-image" />
                  <div className="project-content">
                    <h3 className="project-title">Travel Website</h3>
                    <p className="project-description">
                    Created a travel website with destination search, travel guides, and booking options. Implemented secure user authentication and used technologies like HTML, CSS, JavaScript and Bootstrap for a responsive design. Enhancing the overall aesthetic and user experience.
                    </p>
                    {/* Add Tech Badges here */}
                    <div className="tech-badges">
                    <span className="badge badge-tailwind">Python Flask</span>
                    <span className="badge badge-html">HTML</span>
                    <span className="badge badge-css">Bootstrap</span>
                    <span className="badge badge-js">JavaScript</span>
                   </div>
                  </div>
                </div>

                <div className="relative project-card">
                  <img src={project2Image} alt="Phishing Detection App" className="project-image" />
                  <div className="project-content">
                    <h3 className="project-title">Phishing Scanner</h3>
                    <p className="project-description">
                    Developed a Phishing Detection App using Python, Flask, and Tailwind CSS. It scans emails and URLs for threats, producing detailed reports with scores and verification from multiple cyber-intelligence sources, utilizing APIs like Hunter API and Google Safe Browsing for comprehensive analysis.
                    </p>
                    {/* Add Tech Badges here */}
                    <div className="tech-badges">
                    <span className="badge badge-tailwind">Python</span>
                    <span className="badge badge-html">JS</span>
                    <span className="badge badge-css">TailwindCSS</span>
                    <span className="badge badge-js">HTML</span>
                    <span className="badge badge-tailwind">Hunter.IO API</span>
                    <span className="badge badge-css">VirusTotal API</span>
                   </div>
                  </div>
                </div>

                <div className="relative project-card">
                  <img src={project3Image} alt="Third Project" className="project-image" />
                  <div className="project-content">
                    <h3 className="project-title">Front End Development</h3>
                    <p className="project-description">
                    Developed an interactive travel guide featuring major UK destinations like London and more. Utilized HTML, CSS, and JavaScript to create a responsive and user-friendly interface with detailed information and vibrant visuals.
                    </p>
                    {/* Add Tech Badges here */}
                    <div className="tech-badges">
                    <span className="badge badge-tailwind">JavaScript</span>
                    <span className="badge badge-html">HTML</span>
                    <span className="badge badge-css">CSS</span>
                   </div>
                  </div>
                </div>

                <div className="relative project-card">
                 <img src={project4Image} alt="Fourth Project" className="project-image" />
                 <div className="project-content">
                  <h3 className="project-title">Twin City Explorer</h3>
                  <p className="project-description">
                  Collaborated in a group project developing a City Explorer web application using JS and PHP, integrating Google Maps API and OpenWeather API to provide weather forecasts and POI data for twin-cities like Chicago and Birmingham. The app features interactive map markers, real-time weather updates, and comprehensive POI details that users can explore with a click. 
                 </p>
                 {/* Add Tech Badges here */}
                 <div className="tech-badges">
                    <span className="badge badge-tailwind">PHP</span>
                    <span className="badge badge-html">OpenWeather API</span>
                    <span className="badge badge-css">GoogleMaps API</span>
                    <span className="badge badge-js">Flickr API</span>
                   </div>
               </div>
              </div>


              <div className="relative project-card">
                 <img src={project5Image} alt="Fifth Project" className="project-image" />
                 <div className="project-content">
                  <h3 className="project-title"> React Portfolio</h3>
                  <p className="project-description">
                  Developed a personal portfolio website showcasing my skills, projects, and experiences as a software developer. The site features a responsive, modern design, built with React and Tailwind CSS for efficient styling and performance. Key features include interactive sections, animated components, and an intuitive navigation bar.
                 </p>
                 {/* Add Tech Badges here */}
                 <div className="tech-badges">
                    <span className="badge badge-tailwind">React</span>
                    <span className="badge badge-html">Tailwind CSS</span>
                    <span className="badge badge-css">CSS</span>
                   </div>
               </div>
              </div>
              

              <div className="relative project-card">
                 <img src={project6Image} alt="Sixth Project" className="project-image" />
                 <div className="project-content">
                  <h3 className="project-title"> Arduino -  Ultrasonic Sensor Monitoring System</h3>
                  <p className="project-description">
                  Developed an Arduino-based system using an ultrasonic sensor to measure object distance. Programmed in Arduino (C), it featured real-time feedback displayed on an LCD and auditory alerts via a buzzer.
                 </p>
                 {/* Add Tech Badges here */}
                 <div className="tech-badges">
                    <span className="badge badge-tailwind">ARDUINO</span>
                    <span className="badge badge-html">C</span>
                    
                   </div>
               </div>
              </div>

              </div>
            </div>
          </section>

          <section id="technologies-education" className="py-16 w-full">
  <div className="text-center mb-12">
    <h2 className="text-4xl font-bold mb-4 text-white">Technologies & Education</h2>
  </div>

  <div className="flex flex-col md:flex-row justify-center items-center md:items-start gap-12">
    {/* Education Section */}
    <div className="bg-gradient-to-r from-purple-700 to-purple-900 p-8 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-purple-500/50">
      <h3 className="text-2xl font-bold text-white mb-4">Education</h3>
      <ul className="text-gray-300 space-y-4">
        <li>
          <strong className="font-extrabold text-gray-200">Degree:</strong>
          <span className=" text-md text-gray-200 font-semibold bg-purple-900 py-1 px-2 rounded-lg mt-2 inline-block">
            BSc in Software Engineering for Business
          </span>
          <p className="text-md text-gray-200 font-semibold">University of the West of England</p>
        </li>
        <li>
          <strong className="font-extrabold text-gray-200">Key Modules:</strong>
          <ul className="list-disc list-inside text-gray-200 mt-2">
            <li className="font-semibold">Object Oriented Systems Development</li>
            <li className="font-semibold">Data Structures and Algorithms</li>
            <li className="font-semibold">Advanced Databases</li>
            <li className="font-semibold">IT Practice: Consultancy Project</li>
            <li className="font-semibold">Data, Schemas, and Applications</li>
            <li className="font-semibold">Software Development Project</li>
          </ul>
        </li>
      </ul>
    </div>

    {/* Technologies Section */}
    <div className="bg-gradient-to-r from-purple-700 to-purple-900 p-8 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-purple-500/50"
      style={{ maxWidth: '500px' }}>
      <h3 className="text-2xl font-bold text-white mb-4">Technologies and Skills</h3>
      <div className="flex flex-wrap gap-4 justify-center">
        <span className="text-white font-bold bg-purple-600 py-2 px-4 rounded-full hover:bg-purple-500 transition-colors">JavaScript</span>
        <span className="text-white font-bold bg-purple-600 py-2 px-4 rounded-full hover:bg-purple-500 transition-colors">Python</span>
        <span className="text-white font-bold bg-purple-600 py-2 px-4 rounded-full hover:bg-purple-500 transition-colors">React</span>
        <span className="text-white font-bold bg-purple-600 py-2 px-4 rounded-full hover:bg-purple-500 transition-colors">PHP</span>
        <span className="text-white font-bold bg-purple-600 py-2 px-4 rounded-full hover:bg-purple-500 transition-colors">HTML</span>
        <span className="text-white font-bold bg-purple-600 py-2 px-4 rounded-full hover:bg-purple-500 transition-colors">CSS</span>
        <span className="text-white font-bold bg-purple-600 py-2 px-4 rounded-full hover:bg-purple-500 transition-colors">Data Structures and Algorithms</span>
        <span className="text-white font-bold bg-purple-600 py-2 px-4 rounded-full hover:bg-purple-500 transition-colors">TailwindCSS</span>
        <span className="text-white font-bold bg-purple-600 py-2 px-4 rounded-full hover:bg-purple-500 transition-colors">C</span>
        <span className="text-white font-bold bg-purple-600 py-2 px-4 rounded-full hover:bg-purple-500 transition-colors">UI Design</span>
      </div>
    </div>
  </div>
</section>

<section id="contact" className="py-16">
  <div className="contact-form">
    <h2 className="text-3xl font-bold mb-4 text-center">Contact Me</h2>
    <p className="text-lg text-center mb-6">Feel free to send me an email! Or message me on LinkedIn.</p>
    <p className="text-lg text-center mb-6">Raiyanabedinn@gmail.com | @RaiyanAbedin</p>
    {/* Email Button or Email Display */}
    <div className="flex justify-center">
    <a href="mailto:raiyanabedinn@gmail.com" className="contact-button position-center">
      Send Email
    </a>
    </div>
  </div>
</section>


        </div>
      </main>
      <footer className="w-full py-8 text-center text-white custom-project-bg">
        <p>Thanks for stopping by!</p>
        <p>&copy; 2024 Raiyan Abedin | Raiyanabedin.com</p>
      </footer>
    </>
  );
};

export default App;
